const isBrowser = typeof window !== `undefined`;

export default url => {
  const internal = /^\/(?!\/)/.test(url);
  if (!isBrowser) return null;
  const { origin } = window.location;

  if (internal) {
    return window.location.assign(`${origin}${url || ''}`);
  }
  window.open(url);
};
