import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "@fitplan/lib/components/Link";

import { getUser } from "../../utils/auth";

import navigate from "../../utils/navigate";
import { localeTextGetter } from "../../utils/l10n";

import "tailwindcss/base.css";
import "tailwindcss/components.css";
import "tailwindcss/utilities.css";

export interface Props {
    currentPage: string;
}

const AccountLayout: React.FunctionComponent<Props> = ({
    currentPage,
    children,
}) => {
    const data = useStaticQuery(graphql`
        query accountNavQuery {
            sanityAccount {
                account {
                    accountNavbar {
                        accountOverview {
                            _type
                            en
                            es
                        }
                        editProfile {
                            _type
                            en
                            es
                        }
                        subscription {
                            _type
                            en
                            es
                        }
                    }
                }
            }
        }
    `);
    const {
        sanityAccount: { account },
    } = React.useMemo(() => localeTextGetter(data), [data]);

    return (
        <div>
            <div className="sm:hidden p-4">
                <select
                    aria-label="Selected tab"
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                    value={currentPage}
                    onChange={event => {
                        navigate(event.target.value);
                    }}
                >
                    <option value={"/account"}>
                        {account.accountNavbar.accountOverview}
                    </option>
                    <option value={"/account/profile"}>
                        {account.accountNavbar.editProfile}
                    </option>
                    <option value={"/account/subscription"}>
                        {account.accountNavbar.subscription}
                    </option>
                    <option value={"/account/redeem"}>Redeem Code</option>
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200 px-8">
                    <nav className="-mb-px flex">
                        <Link
                            to="/account"
                            className={
                                currentPage === "/account"
                                    ? classSelected
                                    : classMenu
                            }
                        >
                            {account.accountNavbar.accountOverview}
                        </Link>
                        <Link
                            to="/account/profile"
                            className={`ml-8 ${
                                currentPage === "/account/profile"
                                    ? classSelected
                                    : classMenu
                            }`}
                        >
                            {account.accountNavbar.editProfile}
                        </Link>
                        <Link
                            to="/account/subscription"
                            className={`ml-8 ${
                                currentPage === "/account/subscription"
                                    ? classSelected
                                    : classMenu
                            }`}
                        >
                            {account.accountNavbar.subscription}
                        </Link>
                        <Link
                            to="/account/redeem"
                            className={`ml-8 ${
                                currentPage === "/account/redeem"
                                    ? classSelected
                                    : classMenu
                            }`}
                        >
                            Redeem Code
                        </Link>
                    </nav>
                </div>
            </div>
            {children}
        </div>
    );
};

const classSelected =
    "whitespace-no-wrap py-6 px-1 border-b-2 border-brand-500 font-bold text-xl leading-5 text-brand-400 focus:outline-none focus:text-brand-500 focus:border-brand-700";
const classMenu =
    "whitespace-no-wrap py-6 px-1 border-b-2 border-transparent font-bold text-xl leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";

export default AccountLayout;
